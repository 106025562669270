/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import kebabCase from 'lodash/kebabCase';
import numeral from 'numeral';
import { Select, Button, Switch, Form, Typography, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

import { trackEvent } from 'analytics';
import { preset } from 'styles';
import { useCheckPermission } from 'modules';
import { InsightActions } from '@otso/auth-wrapper';

const { Option } = Select;
const { Title } = Typography;

const DocumentListHeader = ({
  // pagination
  pageSize,
  setPageSize,
  // datasets
  datasetIds = [],
  datasetsById,
  // documents
  documents = [],
  totalDocuments,
  // orderBy
  order,
  setOrder,
  // special document filter toggle
  specialFiltering = {},
  hideDuplicatedDocuments = true,
  setHideDuplicatedDocuments,
  showParentDocmentsOnly = true,
  setShowParentDocmentsOnly,
}) => {
  const documentsLength = documents.length;

  const { hasDuplicateDocuments = false, hasDocumentThread = false } =
    specialFiltering;

  const datasetNames = useMemo(
    () => datasetIds.map((datasetId) => datasetsById[datasetId].name),
    [datasetIds, datasetsById]
  );

  const userCanExport = useCheckPermission([InsightActions.downloadDocuments]);

  const disableExport =
    process.env.REACT_APP_DISABLE_EXPLORE_EXPORT === 'true' || !userCanExport;

  const exportDocuments = () => {
    trackEvent('Export Explore Documents', {
      datasetNames: datasetNames.join(' '),
      documentsLength,
    });

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const stringfiedDocuments = documents.map((document) => {
      const dataset = datasetsById[document.datasetId];
      const { fields } = dataset.schema || {};
      return fields.reduce((prev, field) => {
        if (
          field.name === 'geo_location' ||
          field.type === 'record' ||
          field.hidden ||
          field.name === 'otso_doc_enriched_entities'
        ) {
          return prev;
        }
        let fieldValue = document[field.name];
        if (
          typeof fieldValue === 'undefined' ||
          (typeof fieldValue === 'object' && !fieldValue)
        ) {
          fieldValue = '';
        } else if (typeof fieldValue !== 'string') {
          fieldValue = JSON.stringify(fieldValue);
        }
        return {
          ...prev,
          [field.name]: fieldValue,
        };
      }, {});
    });

    const ws = utils.json_to_sheet(stringfiedDocuments);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const fileData = new window.Blob([excelBuffer], { type: fileType });

    saveAs(fileData, `${kebabCase(datasetNames.join(' ').slice(0, 100))}.xlsx`);
  };

  return (
    <div
      css={{
        paddingTop: preset.spacing(2),
        paddingBottom: preset.spacing(2),
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: preset.spacing(5),
        }}
      >
        <div
          css={{
            flex: 1,
            minWidth: 0,
            display: 'flex',
            alignItems: 'center',
            marginRight: preset.spacing(5),
            h4: {
              marginBottom: 0,
            },
          }}
        >
          <Tooltip title={datasetNames.join(', ')}>
            <Title ellipsis level={4}>
              {datasetNames.join(', ')}
            </Title>
          </Tooltip>
          <span
            css={{
              flexShrink: 0,
              fontSize: 14,
              fontWeight: 400,
              marginLeft: 12,
              marginRight: 12,
            }}
          >
            {typeof totalDocuments === 'number' ? 'Displaying' : 'First'}{' '}
            <Select
              size="small"
              value={documentsLength || pageSize}
              onChange={setPageSize}
              dropdownMatchSelectWidth={false}
            >
              {[15, 100, 500, 1000].map((limit) => (
                <Option value={limit} key={limit}>
                  {limit}
                </Option>
              ))}
            </Select>{' '}
            {typeof totalDocuments === 'number'
              ? `of ${numeral(totalDocuments).format('0,0')} Total Results`
              : 'results'}
          </span>
          {disableExport || (
            <Button
              icon={<DownloadOutlined />}
              size="small"
              onClick={exportDocuments}
            >
              {`Export ${numeral(documentsLength).format(
                '0,0'
              )} Displayed Results`}
            </Button>
          )}
        </div>
        <Form layout="inline">
          {hasDuplicateDocuments && (
            <Form.Item label="Show Distinct Documents Only">
              <Switch
                checked={!!hideDuplicatedDocuments}
                onChange={(checked) => setHideDuplicatedDocuments(checked)}
              />
            </Form.Item>
          )}
          {hasDocumentThread && (
            <Form.Item label="Show Parent Documents Only">
              <Switch
                checked={!!showParentDocmentsOnly}
                onChange={(checked) => setShowParentDocmentsOnly(checked)}
              />
            </Form.Item>
          )}
          <Form.Item css={{ marginRight: '0 !important' }}>
            <Select
              defaultValue="newest"
              dropdownMatchSelectWidth={false}
              value={order}
              onChange={(value) => setOrder(value)}
            >
              <Option value="newest">Newest</Option>
              <Option value="oldest">Oldest</Option>
              <Option value="score">Relevance Score</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default DocumentListHeader;
