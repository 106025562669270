export const AUS_COORD = {
  center: { lat: -25.2744, lng: 133.7751 },
  zoom: 4,
};

export const MAP_PROPERTY_STATE_KEY = 'feature_state';

export const AUS_GEO_JSON = {
  state: {
    geoJson:
      'https://storage.googleapis.com/otso-geojson/australia/state_topo.json',
    idPropertyName: 'STATE_NAME',
    defaultZoom: 4,
  },
  queensland: {
    lga: {
      geoJson:
        'https://storage.googleapis.com/otso-geojson/australia/queensland/lga_formatted_2-percent_topo.json',
      idPropertyName: 'long_lga_name',
      defaultZoom: 8,
      councils: {
        lcc: {
          geoJson:
            'https://storage.googleapis.com/otso-geojson/australia/queensland/lcc_division_topo.json',
          idPropertyName: 'Division_N',
          defaultZoom: 10.5,
        },
      },
    },
    suburb: {
      geoJson:
        'https://storage.googleapis.com/otso-geojson/australia/queensland/suburb_formatted_2-percent_topo.json',
      idPropertyName: 'unique_name',
      defaultZoom: 12,
    },
  },
};
