/** @jsxImportSource @emotion/react */
import { useRef, useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { connect } from 'react-redux';
import { Spin, message } from 'antd';
import axios from 'axios';
import isEqual from 'lodash/isEqual';

import { preset } from 'styles';
import googleMapStyle from 'constant/geo/googleMapStyle.json';
import { AUS_COORD, MAP_PROPERTY_STATE_KEY } from 'constant/geo';
import formatMetricLabel from '@modules/chart/formatMetricLabel';
import getColorByMetric from '@modules/chart/getColorByMetric';
import getGeoJsonConfigByDimension from '@modules/geo/getGeoJsonConfigByDimension';
import filterGeoJson from '@modules/geo/filterGeoJson';
import ChoroplethMapLegend from './components/ChoroplethMapLegend';

const ChoroplethMap = ({
  height,
  data,
  queryObj,
  config,
  color,
  drillDownDisabled = false,
  autoZoom = true,
  loading = false,
  onZoomChange,
  onDbClick,
  // redux dispatch for triggering drilldown
  dispatch,
}) => {
  const [isInitialised, setIsInitialised] = useState(false);
  const [isPolygonLoading, setIsPolygonLoading] = useState(false);

  const mapEleRef = useRef();
  const mapObjRef = useRef();
  const infoWindowRef = useRef();
  const zoomListenerRef = useRef();
  const mapDataListenerRef = useRef({});
  const dbClickRef = useRef();
  const dbClickListenerRef = useRef();
  const geoJsonRef = useRef();
  const queryObjRef = useRef({});

  useEffect(() => {
    const initGoogleMap = async () => {
      try {
        const loader = new Loader({
          apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
          version: 'weekly',
        });
        await loader.load();

        const map = new window.google.maps.Map(mapEleRef.current, {
          center: AUS_COORD.center,
          zoom: AUS_COORD.zoom,
          mapTypeControl: false,
          streetViewControl: false,
          styles: googleMapStyle,
        });

        const infoWindow = new window.google.maps.InfoWindow();

        mapObjRef.current = map;
        infoWindowRef.current = infoWindow;

        setIsInitialised(true);
      } catch (error) {
        message.error(error.message);
      }
    };

    if (mapEleRef.current) {
      initGoogleMap();
    }
  }, []);

  useEffect(() => {
    const clearListener = () => {
      if (zoomListenerRef.current) {
        zoomListenerRef.current.remove();
      }
    };

    if (isInitialised && mapObjRef.current) {
      const map = mapObjRef.current;
      clearListener();
      if (onZoomChange) {
        zoomListenerRef.current = map.addListener('zoom_changed', () => {
          const newZoom = map.getZoom();
          onZoomChange(newZoom);
        });
      }
    }

    return () => {
      clearListener();
    };
  }, [isInitialised, onZoomChange]);

  useEffect(() => {
    const map = mapObjRef.current;

    const clearListener = () => {
      ['mouseover', 'mouseout', 'click'].forEach((listenerKey) => {
        if (mapDataListenerRef.current[listenerKey]) {
          mapDataListenerRef.current[listenerKey].remove();
        }
      });
    };

    const loadMapPolygon = async () => {
      const { metrics = [], dimensions = [] } = queryObj;

      const locationDimensionKey = dimensions[0].as;

      const { geoJson, idPropertyName, defaultZoom } =
        getGeoJsonConfigByDimension(locationDimensionKey);

      const isMapDataLoaded = isEqual(queryObj, queryObjRef.current);

      if (!isMapDataLoaded) {
        setIsPolygonLoading(true);

        let rawGeoJson;

        if (geoJsonRef.current && geoJsonRef.current[locationDimensionKey]) {
          rawGeoJson = geoJsonRef.current[locationDimensionKey];
        } else {
          const { data: geoJsonData } = await axios.get(geoJson);
          geoJsonRef.current = {
            ...(geoJsonRef.current || {}),
            [locationDimensionKey]: geoJsonData,
          };
          rawGeoJson = geoJsonData;
        }

        map.data.forEach((feature) => {
          map.data.remove(feature);
        });

        const filteredGeoJson = filterGeoJson(rawGeoJson, {
          idPropertyName,
          data,
          dimensions,
        });
        map.data.addGeoJson(filteredGeoJson, { idPropertyName });

        let polygonCoord;

        data.forEach((row) => {
          const locationDimensionValue = row[locationDimensionKey];
          if (locationDimensionValue) {
            const featureId = locationDimensionValue;
            const feature = map.data.getFeatureById(featureId);
            if (feature) {
              feature.getGeometry().forEachLatLng((latLng) => {
                if (!polygonCoord) {
                  polygonCoord = latLng;
                }
              });
              feature.setProperty(locationDimensionKey, locationDimensionValue);
              metrics.forEach((metric) => {
                feature.setProperty(metric.as, row[metric.as]);
              });
            }
          }
        });

        if (polygonCoord && autoZoom) {
          map.setZoom(defaultZoom);
          map.panTo(polygonCoord);
        }

        queryObjRef.current = queryObj;

        setIsPolygonLoading(false);
      }

      // Load InfoWindow
      if (infoWindowRef.current) {
        clearListener();

        const infoWindow = infoWindowRef.current;
        mapDataListenerRef.current.mouseover = map.data.addListener(
          'mouseover',
          (e) => {
            e.feature.setProperty(MAP_PROPERTY_STATE_KEY, 'hover');

            let html = '';
            [...metrics, ...dimensions].forEach((obj) => {
              const value = e.feature.getProperty(obj.as);
              const formattedValue = formatMetricLabel(value);
              if (formattedValue) {
                html = `${html}${html ? '<br />' : ''}${
                  obj.as
                }: ${formattedValue}`;
              }
            });
            infoWindow.setContent(html);
            infoWindow.setPosition(e.latLng);
            infoWindow.setOptions({
              pixelOffset: new window.google.maps.Size(0, -preset.spacing(3)),
            });
            infoWindow.open(map);
          }
        );

        mapDataListenerRef.current.mouseout = map.data.addListener(
          'mouseout',
          (e) => {
            e.feature.setProperty(MAP_PROPERTY_STATE_KEY, 'normal');
            infoWindow.close();
          }
        );

        mapDataListenerRef.current.click = map.data.addListener(
          'click',
          (e) => {
            setTimeout(() => {
              if (!drillDownDisabled && !dbClickRef.current) {
                const mapEle = mapEleRef.current;

                const { x, y } = mapEle.getBoundingClientRect();

                const targetData = [...metrics, ...dimensions].reduce(
                  (prev, obj) => ({
                    ...prev,
                    [obj.as]: e.feature.getProperty(obj.as),
                  }),
                  {}
                );

                dispatch({
                  type: 'SET_DRILLDOWN',
                  drilldown: {
                    x: e.domEvent.x - x + preset.spacing(2),
                    y: e.domEvent.y - y + preset.spacing(2),
                    chartElement: mapEle,
                    targetData,
                    queryObj,
                  },
                });
              } else if (dbClickRef.current) {
                dbClickRef.current = false;
              }
            }, 300);
          }
        );
      }
    };

    if (
      isInitialised &&
      mapObjRef.current &&
      mapEleRef.current &&
      queryObj.dimensions.length > 0
    ) {
      if (data.length > 0) {
        loadMapPolygon();
      }
    }

    return () => {
      clearListener();
    };
  }, [isInitialised, data, queryObj, dispatch, drillDownDisabled, autoZoom]);

  useEffect(() => {
    const clearListener = () => {
      if (dbClickListenerRef.current) {
        dbClickListenerRef.current.remove();
      }
    };

    if (isInitialised && mapObjRef.current) {
      const map = mapObjRef.current;
      clearListener();
      if (onDbClick) {
        dbClickListenerRef.current = map.data.addListener('dblclick', (e) => {
          e.stop();
          dbClickRef.current = true;
          onDbClick(e.feature);
        });
      }
    }

    return () => {
      clearListener();
    };
  }, [isInitialised, onDbClick]);

  useEffect(() => {
    const setMapStyle = () => {
      const map = mapObjRef.current;

      let currentIdPropertyName;

      if (config.x) {
        const { idPropertyName } = getGeoJsonConfigByDimension(config.x);
        currentIdPropertyName = idPropertyName;
      }

      map.data.setStyle((feature) => {
        const isFeatureVisible = currentIdPropertyName
          ? !!feature.getProperty(currentIdPropertyName)
          : false;

        if (!isFeatureVisible) {
          return { visible: false };
        }

        const colorMetricValue = config.colorBy
          ? feature.getProperty(config.colorBy)
          : null;

        if (colorMetricValue) {
          const colorScale = getColorByMetric({
            data,
            config,
            color,
            colorBy: config.colorBy,
          });
          const isHover =
            feature.getProperty(MAP_PROPERTY_STATE_KEY) === 'hover';
          return {
            strokeWeight: isHover ? 2 : 0.5,
            zIndex: isHover ? 2 : 1,
            strokeColor: '#fff',
            fillColor: colorScale(colorMetricValue),
            fillOpacity: 0.75,
          };
        }

        return { visible: false };
      });
    };

    if (isInitialised && mapObjRef.current) {
      setMapStyle();
    }
  }, [isInitialised, color, config]);

  return (
    <Spin spinning={isPolygonLoading || !isInitialised || loading}>
      <div css={{ width: '100%', height, position: 'relative' }}>
        <div ref={mapEleRef} css={{ width: '100%', height: '100%' }} />

        <ChoroplethMapLegend color={color} config={config} />
      </div>
    </Spin>
  );
};

export default connect()(ChoroplethMap);
